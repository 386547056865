<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    isLoading: false,
    isReasonModalOpen: false,
    reason: {
      choice: '',
      text: '',
    },
  }),
  computed: {
    ...mapGetters('auth', [
      'hasPercentPlan',
    ]),
  },
  methods: {
    deleteAccount() {
      if (this.hasPercentPlan) {
        this.isReasonModalOpen = true;
        return;
      }

      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Attention',
        message: `
          <p>
            La suppression de compte
            est définitive.
          </p>
          <p class="mt-3">
            Afin de garder votre compte,
            vous pouvez tout simplement résilier votre abonnement,
            et repasser au Pack DÉCOUVERTE.
          </p>
          <p class="mt-3 has-text-weight-bold">
            Voulez-vous quand même supprimer votre compte ?
          </p>
        `,
        focusOn: 'cancel',
        confirmText: 'Oui, supprimez-le',
        onConfirm: () => (this.isReasonModalOpen = true),
      });
    },
    confirmDelete() {
      const cancel_reason = this.reason.choice;
      const cancel_additional_text = this.reason.text;
      this.$buefy.dialog.prompt({
        message: 'Veuillez saisir votre mot de passe pour confirmer',
        type: 'is-danger',
        inputAttrs: { type: 'password' },
        confirmText: 'Confirmer',
        onConfirm: (password) => {
          this.isLoading = true;
          this.isReasonModalOpen = false;
          this.$store.dispatch('auth/delete', { password, cancel_reason, cancel_additional_text })
            .finally(() => (this.isLoading = false));
        },
      });
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Désactiver le compte
      </h2>
      <ul>
        <li>Vous arrêtez votre activité ?</li>
        <li>Notre outil ne vous convient plus ?</li>
      </ul>
      <p>Vous pouvez désactiver votre compte.</p>
      <p>
        Pour les Pack PRO, la mensualité de la période d'abonnement en cours
        reste due et ne fera l'objet d'aucun remboursement.
      </p>
    </div>

    <div class="column">
      <div class="box content">
        <p class="has-text-danger">
          ATTENTION ! Vos apprenants ne pourront plus accéder à vos formations ni à leur compte.
        </p>
        <p>
          La désactivation du compte se traduit par la suppression immédiate de l'accès à votre espace.
        </p>
        <p>
          <b-button
            type="is-danger"
            :loading="isLoading"
            @click="deleteAccount">
            Désactiver le compte
          </b-button>
        </p>
      </div>

      <b-modal v-if="isReasonModalOpen" :active.sync="isReasonModalOpen" has-modal-card>
        <form class="modal-card has-background-white bradius-8" @submit.prevent="confirmDelete">
          <div class="modal-card-body content mb-0">
            <h2 class="is-size-4">
              Nous sommes tristes de vous voir partir...
            </h2>
            <h3 class="is-size-5">
              Pour suspendre les inscriptions
            </h3>
            <p>
              Vous avez la possibilité de
              <template v-if="$store.getters['auth/hasPercentPlan']">
                changer d'offre en passant gratuitement au
                <strong>Pack DÉCOUVERTE</strong>, et
              </template>
              désactiver les inscritions à vos formations
              dans <br> <strong>Menu > Paramètres > Général</strong>.
            </p>
            <h3 class="is-size-5">
              Pour supprimer votre compte définitivement
            </h3>
            <p>
              Merci de remplir la raison de votre départ afin que nous puissions améliorer Teachizy.
            </p>

            <b-field label="Choisissez une raison">
              <b-select v-model="reason.choice" required expanded>
                <option value="NOT_ENOUGH_SALES" v-t="'quit_reasons.NOT_ENOUGH_SALES'" />
                <option value="NOT_ENOUGH_TIME" v-t="'quit_reasons.NOT_ENOUGH_TIME'" />
                <option value="ALTERNATIVE_FOUNDED" v-t="'quit_reasons.ALTERNATIVE_FOUNDED'" />
                <option value="MISSING_FEATURES" v-t="'quit_reasons.MISSING_FEATURES'" />
                <option value="PRODUCT_NOT_BUILT" v-t="'quit_reasons.PRODUCT_NOT_BUILT'" />
                <option value="TOO_EXPENSIVE" v-t="'quit_reasons.TOO_EXPENSIVE'" />
                <option value="TOO_HARD" v-t="'quit_reasons.TOO_HARD'" />
                <option value="OTHER" v-t="'quit_reasons.OTHER'" />
              </b-select>
            </b-field>

            <b-field label="Précisez votre réponse">
              <b-input v-model="reason.text" required />
            </b-field>
          </div>
          <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button
              label="Annuler"
              @click="isReasonModalOpen = false"
            />
            <b-button
              type="is-primary"
              label="Désactiver le compte"
              native-type="submit"
            />
          </footer>
        </form>
      </b-modal>
    </div>
  </div>
</template>
